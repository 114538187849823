<template>
  <div class="Degree-Info">
    <el-row class="group" v-if="queryRoot">
      <el-col
        :sm="24"
        :md="12"
        :lg="8"
        class="item"
        v-for="(item, key) in form"
        :key="item + '_' + key"
      >
        <p class="label">{{ label[key] }}</p>
        <p class="value">{{ item }}</p>
      </el-col>
    </el-row>
    <no-root v-else></no-root>
  </div>
</template>

<script>
import { QueryEduDegStuByStuId } from "@/libs/api/student";
export default {
  name: "degree-info",
  data() {
    return {
      label: {
        studentName: "姓名",
        studentType: "学生类型",
        degreeStatuss: "学位状态",
        degreeTypes: "学位类型",
        degreeCertNo: "学位证书编号",
        degreeTime: "学位证书授予时间",
        // getWay: "领取方式",
        // getTimes: "领取时间",
        // courierNo: "快递单号",
      },
      form: {
        studentName: "",
        studentType: "",
        degreeStatuss: "",
        degreeTypes: "",
        degreeCertNo: "",
        degreeTime: "",
        // getWay: "暂无",
        // getTimes: "暂无",
        // courierNo: "暂无",
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    queryRoot() {
      return this.$sysPerKey("eduGraduateDegreeSet:queryEduDegStuByStuId");
    },
  },
  watch: {
    queryRoot(n) {
      n && this.init();
    },
  },
  mounted() {
    this.queryRoot && this.init();
  },
  methods: {
    init() {
      // let formData = new FormData();
      // formData.append("studentId", this.user.id);
      QueryEduDegStuByStuId({ studentId: this.user.id }).then((res) => {
        if (res.data) {
          for (const key in res.data) {
            const item = res.data[key];
            if (this.label[key]) {
              this.form[key] = item;
            }
          }
          const studentType = this.form.studentType;
          this.form.studentType = studentType === 0 ? "在读生" : "毕业生";
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.Degree-Info {
  .group {
    position: relative;
    font-size: 14px;
    display: flex;
    flex-flow: row wrap;
    background: #fff;
    margin-bottom: 14px;
    .item {
      display: flex;
      p {
        padding: 12px;
        border: 1px solid #f0f0f0;
        margin: 0px -1px -1px 0px;
      }
      @labelWidth: 140px;
      .label {
        background: #fafafa;
        max-width: @labelWidth;
        min-width: @labelWidth;
      }
      .value {
        width: calc(100% - @labelWidth);
      }
    }
  }
}
</style>