<template>
  <div class="Degree-Results">
    <el-alert
      v-if="queryTimeShow"
      title="不在申报时间内"
      type="error"
    ></el-alert>
    <el-table
      :data="tableData"
      @selection-change="handleSelectionChange"
      stripe
      v-loading="loading"
      :height="300"
    >
      <el-table-column type="selection" align="center" fixed></el-table-column>
      <el-table-column label="序号" :width="60" align="center" fixed>
        <template slot-scope="scope">
          <span>{{ scope.$index + (current - 1) * pageSize + 1 }} </span>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) in col"
        :key="index"
        :prop="item.prop"
        :label="item.label"
        align="center"
        :show-overflow-tooltip="item.tooltip"
      ></el-table-column>
      <el-table-column prop="attachment" label="附件" align="center">
        <template slot-scope="scope" v-if="scope.row.enclosureUrl">
          <el-button type="text" @click="onAttachment(scope.row)">
            查看附件
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="100"
        fixed="right"
        v-if="delRoot"
      >
        <template slot-scope="scope">
          <el-button
            @click="detail(scope.row)"
            type="text"
            icon="el-icon-info"
          ></el-button>
          <el-button
            @click="onDelete(scope.row, scope.$index)"
            icon="el-icon-delete"
            type="text"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="content">暂无内容</div>
    <el-dialog :visible.sync="info.dialog" title="查看详情" width="30%">
      <el-descriptions
        title=""
        size="medium"
        :column="1"
        v-loading="info.loading"
        border
        style="min-height: 100px"
      >
        <template v-if="info.data">
          <!-- <el-descriptions-item label="学位名称">{{
            info.data.englishName
          }}</el-descriptions-item> -->
          <el-descriptions-item label="成绩类型">{{
            typeFilters(info.data.gradeType)
          }}</el-descriptions-item>
          <el-descriptions-item label="准考证号">{{
            info.data.examNo
          }}</el-descriptions-item>
          <el-descriptions-item label="报考省份">{{
            provinceFilters(info.data.provinceId)
          }}</el-descriptions-item>
          <el-descriptions-item label="考试时间">{{
            info.data.examTime
          }}</el-descriptions-item>
          <el-descriptions-item label="附件地址">
            <el-button
              type="text"
              size="mini"
              style="padding: 0"
              @click="onAttachment(info.data.enclosureUrl)"
            >
              查看附件
            </el-button></el-descriptions-item
          >
          <el-descriptions-item label="证书编号">{{
            info.data.certNo
          }}</el-descriptions-item>
          <el-descriptions-item label="分数">{{
            info.data.score
          }}</el-descriptions-item>
          <el-descriptions-item label="提交时间">{{
            info.data.applyTime
          }}</el-descriptions-item>
          <el-descriptions-item label="审核意见">{{
            info.data.checkSuggestion
          }}</el-descriptions-item>
          <!-- 0等待审核 1审核通过 2审核驳回 -->
          <el-descriptions-item v-if="info.data.checkStatus" label="审核状态">
            <span :class="statusFormat(info.data.checkStatus, true)">{{
              statusFormat(info.data.checkStatus)
            }}</span>
          </el-descriptions-item>
        </template>
      </el-descriptions>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="info.dialog = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="dialog" title="新增学位外语成绩申报" width="30%">
      <el-form
        ref="degreeResultRef"
        :model="formData"
        :rules="rules"
        size="medium"
        label-width="100px"
      >
        <el-form-item label="考试类型" prop="gradeType">
          <el-select
            v-model="formData.gradeType"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="(item, index) in typeOptions"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考试时间" prop="examTime">
          <el-date-picker
            v-model="formData.examTime"
            format="yyyy/MM/dd HH:mm:ss"
            value-format="yyyy/MM/dd HH:mm:ss"
            placeholder="请选择"
            type="datetime"
            clearable
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="报考省份" prop="provinceId">
          <el-select
            v-model="formData.provinceId"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="(item, index) in provinceOptions"
              :key="index"
              :label="item.areaName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="成绩" prop="score">
          <el-input-number
            v-model="formData.score"
            clearable
            :min="0"
            :max="999"
          >
          </el-input-number>
        </el-form-item>
        <el-form-item label="证书编号" prop="certNo">
          <el-input v-model="formData.certNo" placeholder="请输入" clearable>
          </el-input>
        </el-form-item>
        <el-form-item label="准考证号" prop="examNo">
          <el-input
            v-model="formData.examNo"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="上传" prop="upload">
          <el-upload
            ref="upload"
            :action="`${url}hnjxjy-core/eduGraduateDegreeEnglish/insertEduGraduateDegreeEnglish`"
            :data="{
              ...formData,
            }"
            :headers="{
              'x-token': auth['x-token'],
              'x-perm': auth['x-perm'],
            }"
            :on-change="onChange"
            :on-success="handleSuccess"
            :on-remove="handleRemove"
            :file-list="fileList"
            :auto-upload="false"
          >
            <el-button size="small" type="primary" icon="el-icon-upload">
              上传附件
            </el-button>
            <p style="color: red;font-size: 14px; display: inline-block; margin-left: 10px; font-weight: bold;">仅支持office文件格式</p>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="handelConfirm">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  QueryStudentEnglish,
  SelectEduGraduateDegreeEnglishByStudentId,
  DeleteEduEnglishById,
  QueryTime,
  QueryAllEduArea,
} from "@/libs/api/student";
import { BASE_URL } from "@/libs/domain";
export default {
  name: "degree-results",
  data() {
    return {
      loading: false,
      btnLoading: false,
      queryTimeShow: false,
      col: [
        { prop: "gradeTypes", label: "成绩类型", tooltip: true },
        { prop: "applyTime", label: "提交时间", tooltip: true },
        { prop: "checkTime", label: "审核时间", tooltip: true },
        { prop: "checkStatuss", label: "审核状态" },
        { prop: "checkSuggestion", label: "审核意见" },
      ],
      tableData: [],
      current: 1,
      pageSize: 10,
      total: 0,
      selectionData: [],
      dialog: false,
      // 表单
      formData: {
        gradeType: null,
        examTime: null,
        provinceId: null,
        score: null,
        certNo: null,
        examNo: null,
      },
      rules: {
        gradeType: [
          {
            required: true,
            message: "请选择考试类型",
            trigger: "change",
          },
        ],
        examTime: [
          {
            required: true,
            message: "请选择考试时间",
            trigger: "change",
          },
        ],
        provinceId: [
          {
            required: true,
            message: "请选择报考省份",
            trigger: "change",
          },
        ],
        score: [
          {
            required: true,
            message: "请选择成绩",
            trigger: "change",
          },
        ],
        certNo: [],
        examNo: [],
        upload: [
          {
            required: true,
            trigger: "change",
            validator: (rule, value, callback) => {
              if (this.fileList.length === 0) {
                callback(new Error("请选择附件"));
              }
              callback();
            },
          },
        ],
      },
      fileList: [],
      typeOptions: [
        {
          label: "省级学位外语考试",
          value: 0,
        },
        {
          label: "全国英语考试等级三级以上",
          value: 1,
        },
        {
          label: "全国英语等级四级",
          value: 2,
        },
        {
          label: "全国大学英语六级",
          value: 3,
        },
      ],
      provinceOptions: [],

      info: {
        loading: false,
        dialog: false,
        data: null,
      },
    };
  },
  computed: {
    datalist() {
      return this.tableData.slice(
        (this.current - 1) * this.pageSize,
        this.current * this.pageSize
      );
    },
    user() {
      return this.$store.getters.getUser;
    },
    url() {
      return BASE_URL;
    },
    auth() {
      return this.$store.getters.getAuth;
    },
    queryRoot() {
      return this.$sysPerKey("eduGraduateDegreeSet:queryStudentEnglish");
    },
    delRoot() {
      return this.$sysPerKey("eduGraduateDegreeSet:deleteEduEnglishById");
    },
  },
  watch: {
    queryRoot(n) {
      if (n) {
        this._QueryTime();
        this.init();
      }
    },
  },
  mounted() {
    this.selectInit();
    this.queryRoot && this.init();
    this.queryRoot && this._QueryTime();
  },
  methods: {
    init() {
      this.loading = true;
      // { studentId: this.user.id, schoolId: 1 }
      QueryStudentEnglish()
        .then((res) => {
          this.tableData = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    selectInit() {
      // 省份
      QueryAllEduArea().then((res) => {
        this.provinceOptions = res.data;
      });
    },
    _QueryTime() {
      QueryTime().then((res) => {
        const { applyStartTime, applyEndTime } = res.data;
        let nowDate = new Date();
        let start = new Date(applyStartTime);
        let end = new Date(applyEndTime);
        this.queryTimeShow = nowDate >= start && nowDate <= end ? false : true;
        this.$emit("addShow", !this.queryTimeShow);
      });
    },
    provinceFilters(val) {
      let data = this.provinceOptions.find((f) => f.id == val);
      return data ? data.areaName : val;
    },
    typeFilters(val) {
      let data = this.typeOptions.find((f) => f.value == val);
      return data ? data.label : val;
    },
    statusFormat(status, color) {
      if (color) {
        return status === 0
          ? "warning"
          : status === 1
          ? "success"
          : status === 2
          ? "error"
          : "";
      }
      return status === 0
        ? "等待审核"
        : status === 1
        ? "审核通过"
        : status === 2
        ? "审核驳回"
        : "-";
    },
    onChange(file, fileList) {
      this.fileList = fileList;
    },
    handleSuccess(res) {
      this.$refs.upload.clearFiles();
      this.btnLoading = false;
      if (res.code === 2000) {
        this.dialog = false;
        this.$message.success("提交成功");
        this.init();
      }
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    handleSelectionChange(val) {
      this.selectionData = val;
    },
    onAttachment(row) {
      if (row) {
        window.open(row.enclosureUrl);
      }
    },
    detail(row) {
      if (row) {
        this.info = {
          dialog: true,
          loading: true,
          data: null,
        };
        SelectEduGraduateDegreeEnglishByStudentId({ id: row.id })
          .then((res) => {
            this.info = {
              dialog: true,
              loading: false,
              data: res.data,
            };
          })
          .catch(() => {
            this.info.loading = false;
          });
      }
    },
    onDelete(row, index) {
      if (row) {
        this.$confirm("确定要删除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            DeleteEduEnglishById({ ids: row.id }).then((res) => {
              this.$message.success("删除成功");
              this.init();
            });
          })
          .catch(() => {});
      }
    },
    handelConfirm() {
      this.$refs["degreeResultRef"].validate((valid) => {
        if (!valid) return;
        this.btnLoading = true;
        this.$refs.upload.submit();
      });
    },
  },
};
</script>

<style scoped lang="less">
.Degree-Results {
  .content {
    margin-top: 20px;
    border: 1px solid #ebeef5;
    padding: 20px;
    color: @fontColor;
  }
  .warning {
    color: #ff7000;
  }
  .error {
    color: #ff0000;
  }
  .success {
    color: green;
  }
}
</style>