

<style scoped lang="less">
.Conditions {
}
</style>
<template>
  <div class="Conditions">
      暂无内容
  </div>
</template>

<script>
export default {
  name: "conditions",
  data() {
    return {};
  },
};
</script>