
<style scoped lang="less">
.My-Degree {
  background: #fff;
  padding: 20px;
  position: relative;
  box-shadow: @shadow;
  .tabs {
    margin: 10px -20px;
  }
  .Degree-Results-btn-group {
    position: absolute;
    right: 20px;
    z-index: 1;
    top: 65px;
    width: 130px;
    text-align: right;
  }
}
</style>
<template>
  <div class="My-Degree">
    <div class="stu-module-title">我的学位</div>

    <div class="Degree-Results-btn-group" v-show="activeName === 'two'">
      <el-button type="primary" v-if="addShow" @click="resultAddClick"
        >添加</el-button
      >
      <el-button type="primary" v-if="delRoot" @click="resultDelClick"
        >删除</el-button
      >
    </div>
    <el-tabs v-model="activeName" class="tabs">
      <!-- <el-tab-pane label="学士学位条件" name="one">
        <Conditions />
      </el-tab-pane> -->
      <el-tab-pane label="学位外语成绩申报" name="two">
        <Results ref="degreeResultRef" @addShow="getAddShow" v-if="queryRoot" />
        <no-root v-else></no-root>
      </el-tab-pane>
      <el-tab-pane label="我的学位信息" name="three">
        <Info />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Conditions from "./module/Conditions.vue";
import Results from "./module/Results.vue";
import Info from "./module/Info.vue";
import { QueryStudentEnglish, DeleteEduEnglishById } from "@/libs/api/student";
export default {
  components: { Conditions, Results, Info },
  data() {
    return {
      activeName: "two",
      addShow: false,
    };
  },
  // activated() {
  //   this.init();
  //   },
  computed: {
    queryRoot() {
      return this.$sysPerKey("eduGraduateDegreeSet:queryStudentEnglish");
    },
    delRoot() {
      return this.$sysPerKey("eduGraduateDegreeSet:deleteEduEnglishById");
    },
  },
  methods: {
    // init(){
    //   this.openLoadingView();
    //   this.$axios_supermallData.get('/hnjxjy-core/eduGraduateDegreeSet/queryStudentEnglish').then(res =>{
    //     if(res.data.code===2000){
    //       this.moneyDate = res.data.data
    //     }
    //     this.loadingView.close();
    //   }).catch((err) => {
    //     this.loadingView.close();
    //   });
    // },
    // inits(){
    //   this.openLoadingView();
    //   this.$axios_supermallData.get('/hnjxjy-core/eduGraduateDegreeSet/queryTime').then(res =>{
    //     if(res.data.code===2000){
    //       this.moneyDate = res.data.data
    //     }
    //     this.loadingView.close();
    //   }).catch((err) => {
    //     this.loadingView.close();
    //   });
    // },
    resultAddClick() {
      this.$refs["degreeResultRef"].dialog = true;
    },
    getAddShow(bool) {
      this.addShow = bool;
    },
    resultDelClick() {
      const data = this.$refs["degreeResultRef"].selectionData;
      if (!data.length) {
        return this.$message.error("请至少选择一条数据");
      }
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          DeleteEduEnglishById({ ids: data.map((m) => m.id).join() }).then(
            (res) => {
              this.$message.success("删除成功");
              this.$refs["degreeResultRef"].init();
            }
          );
        })
        .catch(() => {});
    },
  },
};
</script>
